const Interest = [
    "TeleHealth (Consultations, Prescriptions, Diagnostics)",
    "Whitelabel",
    "WhatsApp Bot Integration",
    "Selling Products on Nello",
    "Becoming a Diagnostic Partner",
    "Wellness",
    "Others"



];


export default Interest;