const businessType = [
    "Healthcare Facilities(Hospital, Clinic, etc)",
    "Healthcare Practitioner(Doctor, Specialist, Nurse, etc)",
    "Pharmacy",
    "Diagnostic Center",
    "Company",
    "Others"



];


export default businessType;