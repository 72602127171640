const symptom = [

    "Abdominal pain",
"Back pain",
"Chest pain",
"Headache",
"Fever",
"Fatigue",
"Dizziness",
"Nausea",
"Vomiting",
"Diarrhea",
"Constipation",
"Weight loss",
"Weight gain",
"Shortness of breath",
"Cough",
"Sore throat",
"Swollen glands",
"Joint pain",
"Muscle pain",
"Muscle weakness",
"Numbness or tingling",
"Skin rash",
"Itching",
"Swelling",
"Bruising",
"Bleeding",
"Changes in vision",
"Hearing loss",
"Tinnitus (ringing in the ears)",
"Changes in appetite",
"Difficulty swallowing",
"Excessive thirst",
"Frequent urination",
"Blood in urine",
"Erectile dysfunction",
"Irregular menstrual cycles",
"Abnormal vaginal bleeding",
"Excessive sweating",
"Hair loss",
"Mood swings",
"Anxiety",
"Depression",
"Memory problems",
"Sleep disturbances",
"Night sweats",
"Chest tightness",
"Palpitations",
"Fainting",
"Seizures",
"Confusion"


];

export default symptom;