const procedure = [
    "Abdominoplasty",
    "Amniocentesis",
    "Angioplasty",
    "Appendectomy",
    "Arthroscopy",
    "Bariatric surgery",
    "Bone marrow transplant",
    "Cardiac catheterization",
    "Cardiac surgery",
    "Carotid endarterectomy",
    "Cataract surgery",
    "Cesarean section",
    "Chemotherapy",
    "Cholecystectomy",
    "Circumcision",
    "Colonoscopy",
    "Coronary artery bypass grafting (CABG)",
    "Cystoscopy",
    "Dental extraction",
    "Dental implant",
    "Dilation and curettage (D&C)",
    "Endoscopy",
    "Extracorporeal shockwave lithotripsy (ESWL)",
    "Gallbladder removal",
    "Gastric bypass surgery",
    "Gastric sleeve surgery",
    "Hemodialysis",
    "Hernia repair",
    "Hysterectomy",
    "In vitro fertilization (IVF)",
    "Incision and drainage",
    "Joint replacement surgery",
    "Kidney transplant",
    "Laparoscopic surgery",
    "Laser eye surgery",
    "Liver transplant",
    "Lumbar puncture",
    "Mastectomy",
    "Mohs surgery",
    "Myomectomy",
    "Nephrectomy",
    "Orthopedic surgery",
    "Pacemaker implantation",
    "Pancreas transplant",
    "Pap smear",
    "Plastic surgery",
    "Pneumonectomy",
    "Prostatectomy",
    "Radiation therapy",
    "Retinal detachment surgery",
    "Root canal",
    "Scaling and polishing (S&P)",
    "Skin graft",
    "Spinal fusion",
    "Splenectomy",
    "Tonsillectomy",
    "Tracheostomy",
    "Transurethral resection of the prostate (TURP)",
    "Ultrasound scan",
    "Upper endoscopy",
    "Vasectomy",
    "Ventricular assist device (VAD) implantation",
    "Weight loss surgery",
    "Wisdom tooth extraction",
    "Complete Blood Count (CBC)",
     "Blood Chemistry Panel",
      "Lipid Profile",
       "Urinalysis",
        "Thyroid Function Tests",
         "Blood Glucose Test",
          "Coagulation Panel",
           "Electrolyte Panel",
            "Liver Function Tests (LFTs)",
             "Renal Function Tests",
              "Serology Tests",
               "C-reactive Protein (CRP)",
                "Blood Typing and Crossmatching",
                 "Culture and Sensitivity Tests",
                  "Genetic Tests",
                   "Malaria Test"
];


export default procedure;

